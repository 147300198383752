import Typography from '@mui/material/Typography';
import { Alert, Box, Button, IconButton, InputAdornment, MenuItem, Stack, TextField, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { ChangeEvent, useCallback, useContext, useState } from 'react';
import { SchochStack } from '../../styles/styles';
import SearchIcon from '@mui/icons-material/Search';
import { RapportContext } from '../Provider/RapportContextProvider';
import { MeContext } from '../Provider/MeContextProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import SteigerCatalog from '../SteigerCatalog';
import { CatalogType } from '../../models/catalog-type';
import { debounce } from 'lodash';
import EldasCatalog from '../EldasCatalog';
import NpkCatalog from '../NpkCatalog';
import { Material } from '../../models/material';

const MaterialItem = () => {
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [openAddedMaterial, setOpenAddedMaterial] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.only('xs'));

  const handleAmountChange = (evt: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = Number(evt.target.value);
    if (!isNaN(value) && value >= 0) {
      const updatedMaterials = rapportContext.rapport.materials.map((material, i) => 
        i === index ? { ...material, amount: value } : material
      );
      rapportContext.setRapport(prev => ({ ...prev, materials: updatedMaterials }));
    }
  };

  const handleCatalogChange = (evt: ChangeEvent<HTMLInputElement>) => {
    meContext.setCurrentCatalog(evt.target.value as (CatalogType | ""));
  };

  const handleDeleteClick = (index: number) => {
    const updatedMaterials = rapportContext.rapport.materials.filter((_, i) => i !== index);
    rapportContext.setRapport(prev => ({ ...prev, materials: updatedMaterials }));
  };

  const debouncedSetSearchValue = useCallback(
    debounce((value) => setSearchValue(value), 400), []
  );

  const handleSearchChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    debouncedSetSearchValue(evt.target.value);
  };

  const formatId = (id: number) => {
    const idString = id.toString();
    if (idString.length !== 9) {
      return idString;
    }
  
    return `${idString.slice(0, 3)}.${idString.slice(3, 6)}.${idString.slice(6, 9)}`;
  };

  const displayNoData = () => {
    return (
      <Stack marginTop={2}>
        <Alert severity='info' sx={{ alignItems: 'center'}}>
          <Box sx={{ display: 'flex', flexDirection: isXSScreen ? 'column' : 'row', gap: 2, alignItems: isXSScreen ? 'none' : 'center' }}>
            <Typography variant='inherit'>Kein Katalog ausgewählt.</Typography>
            <Button onClick={meContext.syncCatalogLicences} variant='contained' color='info'>Lizenzen prüfen</Button>
          </Box>
        </Alert>
      </Stack>
    );
  };

  const renderCatalog = () => {
    switch (meContext.currentCatalog) {
      case "steiger":
        return <SteigerCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      case "eldas":
        return <EldasCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      case "npk":
        return <NpkCatalog isXSScreen={isXSScreen} searchValue={searchValue} />;
      default:
        return <>{displayNoData()}</>;
    }
  };

  const searchBar = () => {
    return (
      <SchochStack sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'space-between', justifyContent:'space-between' }}>
        <TextField
          variant='standard'
          label='Katalog durchsuchen'
          disabled={meContext.currentCatalog === ""}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton sx={{ padding: 0, }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ alignSelf: { xs: 'stretch', sm: 'auto' } }}
        />
        <TextField
          variant='standard'
          label='Katalog auswählen'
          select
          value={meContext.currentCatalog}
          onChange={handleCatalogChange}
          sx={{ width: 160 }}
        >
          <MenuItem value="steiger" disabled={!meContext.currentFirm?.hasSteiger}>Steiger</MenuItem>
          <MenuItem value="eldas" disabled={!meContext.currentFirm?.hasEldas}>Eldas</MenuItem>
          <MenuItem value="npk" disabled={!meContext.currentFirm?.hasNpk}>NPK</MenuItem>
        </TextField>
      </SchochStack>
    );
  };

  const addedMaterialItem = (material: Material, index: number) => {
    return (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            flex: 1,
            minWidth: 0,
          }}
        >
          <Typography width="20%">{material.catalogId}</Typography>
          <Tooltip title={material.name} >
            <Typography
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                flex: 1,
                minWidth: 0,
              }}
            >
              {material.name}
            </Typography>
          </Tooltip>
        </Box>
        <TextField
          type='number'
          label='Menge'
          value={material.amount}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleAmountChange(event, index)}
          size='small'
          sx={{ width: 65 }}
        />
        <IconButton sx={{ display: isXSScreen ? 'none' : 'flex'}} onClick={() => handleDeleteClick(index)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <Box>
      <Stack marginTop={2} gap={2}>
        {searchBar()}
        <SchochStack>
          <Box
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}
            onClick={() => setOpenAddedMaterial(prev => !prev)}
          >
            <Typography variant='h6'>Hinzugefügtes Material ({rapportContext.rapport.materials.length})</Typography>
            {openAddedMaterial ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
          {openAddedMaterial && <Stack gap={1}>
            {rapportContext.rapport.materials.map((material, index) => (
              addedMaterialItem(material, index)
            ))}
          </Stack>}
        </SchochStack>
        {renderCatalog()}
      </Stack>
    </Box>
  );
};

export default MaterialItem;