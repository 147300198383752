import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Fab, Link, Stack, Typography } from "@mui/material";
import PaidIcon from '@mui/icons-material/Paid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditIcon from '@mui/icons-material/Edit';
import Titlebar from "../components/Titlebar";
import { Rapport, defaultRapport } from "../models/rapport";
import RapportService from "../services/rapport-service";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import { SchochStack } from "../styles/styles";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AbsoluteText from "../components/AbsoluteText";
import DynamicIcon from "../components/DynamicIcon";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

const RapportPage: React.FC = () => {
  const { id } = useParams();
  const context = useContext(MetaContext);
  const navigate = useNavigate();
  const [rapport, setRapport] = useState<Rapport>(defaultRapport);

  const titles = ['Kunde', 'Ausführungsort', 'Anschrift'];

  const exportRapport = async () => {
    try {
      context.setIsLoading(true);
      await RapportService.exportQuarantine(id!);
      await RapportService.toggleRapportArchived(id!);
      context.setAlertMessage("Der Rapport wurde erfolgreich exportiert und archiviert.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Exportieren ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
};

  const fetchRapport = async () => {
    if (!id) {
      return;
    }

    const data = await RapportService.getRapport(id);
    if (!!data) {
      setRapport(data);
    } else {
      context.setAlertMessage("Daten konnten nicht geladen werden.");
      context.setAlertSeverity("warning");
    }
  };

  useEffect(() => {
    try {
      fetchRapport();
    } catch (err) {
      context.setAlertMessage("Es ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
    }
  }, [])

  return (
    <Stack>
      <Titlebar title="Rapport" iconName="" />
      <Stack sx={{ gap: 2, padding: { sm: 2 }, paddingTop: 0 }}>
        <SchochStack>
          <Typography variant="h4" >{rapport.betreff}</Typography>
          <Typography>{rapport.emilId} ({rapport.projectId})</Typography>
          <Box display='flex' flexDirection='row' gap={2}>
            <Box display='flex' flexDirection='row' gap={1}>
              <HomeRepairServiceIcon color={rapport.jobDone ? "primary" : "action" } />
              <Typography>Arbeit erledigt?</Typography>
            </Box>
            <Box display='flex' flexDirection='row' gap={1}>
              <PaidIcon color={rapport.billed ? "primary" : "action"} />
              <Typography>Verrechnet?</Typography>
            </Box>
          </Box>
          <Typography><strong>Termin:</strong> {(rapport.dueDate !== null) ? rapport.dueDate.toLocaleString('de-DE', { weekday: 'long', day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' }) : '(Kein Datum)'}</Typography>
          <Typography><strong>Beschreibung: </strong> {rapport.description}</Typography>
        </SchochStack>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
          <SchochStack>
            <Box display='flex' justifyContent='space-evenly' alignItems='center'>
              <LocationOnIcon fontSize="large" />
              <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Kunde</Typography>
            </Box>
            <Stack gap={2} flex={1}>
              <Typography>{rapport.customers[0].firstname} {rapport.customers[0].lastname}</Typography>
              <Typography>{rapport.customers[0].street}</Typography>
              <Typography>{rapport.customers[0].postalCode} {rapport.customers[0].city}</Typography>
              {!!rapport.customers[0].phone && <Link href={"tel:" + rapport.customers[0].phone} color="inherit"><Typography>{rapport.customers[0].phone}</Typography></Link>}
              {!!rapport.customers[0].mail && <Link href={"mailto:" + rapport.customers[0].mail} color="inherit"><Typography>{rapport.customers[0].mail}</Typography></Link>}
            </Stack>
          </SchochStack>
          <SchochStack>
            <Box display='flex' justifyContent='space-evenly' alignItems='center'>
              <LocationOnIcon fontSize="large" />
              <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Ausführungsort</Typography>
            </Box>
            <Stack gap={2} flex={1}>
              <Typography>{rapport.customers[1].firstname} {rapport.customers[1].lastname}</Typography>
              <Typography>{rapport.customers[1].street}</Typography>
              <Typography>{rapport.customers[1].postalCode} {rapport.customers[1].city}</Typography>
              {!!rapport.customers[1].phone && <Link href={"tel:" + rapport.customers[1].phone} color="inherit">{rapport.customers[1].phone}</Link>}
              {!!rapport.customers[1].mail && <Link href={"mailto:" + rapport.customers[1].mail} color="inherit"><Typography>{rapport.customers[1].mail}</Typography></Link>}
            </Stack>
          </SchochStack>
          <SchochStack>
            <Box display='flex' justifyContent='space-evenly' alignItems='center'>
              <LocationOnIcon fontSize="large" />
              <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Anschrift</Typography>
            </Box>
            <Stack gap={2} flex={1}>
              <Typography>{rapport.customers[2].firstname} {rapport.customers[2].lastname}</Typography>
              <Typography>{rapport.customers[2].street}</Typography>
              <Typography>{rapport.customers[2].postalCode} {rapport.customers[2].city}</Typography>
              {!!rapport.customers[2].phone && <Link href={"tel:" + rapport.customers[2].phone} color="inherit">{rapport.customers[2].phone}</Link>}
              {!!rapport.customers[2].mail && <Link href={"mailto:" + rapport.customers[2].mail} color="inherit"><Typography>{rapport.customers[2].mail}</Typography></Link>}
            </Stack>
          </SchochStack>
        </Box>
        {(() => {
          const rapportInfo = rapport.entries.find(x => x.beName === 'rapportInfo' && x.isActive);
          if (!!rapportInfo) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={rapportInfo.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Auftragsdaten</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const measurements = rapport.entries.find(x => x.beName === 'measurements' && x.isActive);
          if (!!measurements) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={measurements.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Messungen</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const jobs = rapport.entries.find(x => x.beName === 'jobs' && x.isActive);
          if (!!jobs) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={jobs.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Tätigkeiten</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const worktime = rapport.entries.find(x => x.beName === 'worktime' && x.isActive);
          if (!!worktime) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={worktime.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Arbeitszeit</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const material = rapport.entries.find(x => x.beName === 'material' && x.isActive);
          if (!!material) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={material.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Material</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const attachments = rapport.entries.find(x => x.beName === 'attachments' && x.isActive);
          if (!!attachments) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={attachments.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Anhänge</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
      
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        {(() => {
          const signature = rapport.entries.find(x => x.beName === 'signature' && x.isActive);
          if (rapport.signature === null) {
            return null;
          }

          const binaryData = atob(rapport.signature);
          const len = binaryData.length;
          const bytes = new Uint8Array(len);
        
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([bytes], { type: 'image/png' });
          const imageUrl = URL.createObjectURL(blob);

          if (!!signature) {
            return (
              <SchochStack>
                <Box display='flex' flexDirection='row'>
                  <DynamicIcon iconName={signature.iconName} fontSize="large" />
                  <Typography sx={{flex: 1, textAlign: 'center'}} variant="h6">Unterschrift</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, justifyContent: 'center' }}>
                  <img src={imageUrl} alt="Unterschrift" width={'100%'} />
                </Box>
              </SchochStack>
            );
          }

          return null;
        })()}
        <Fab
          color="primary"
          size="small"
          disabled={rapport.isArchived || rapport.isDeleted}
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(20),
            right: (theme) => theme.spacing(4),
          }}
          onClick={() => exportRapport()}
        >
          <FileUploadIcon />
        </Fab>
        <Fab
          color="primary"
          disabled={rapport.isArchived || rapport.isDeleted}
          sx={{
          position: 'fixed',
          bottom: (theme) => theme.spacing(10),
          right: (theme) => theme.spacing(3)
          }}
          onClick={() => {navigate(`/rapport/${id}/edit`)}}
        >
          <EditIcon />
        </Fab>
        {rapport.isDeleted && <AbsoluteText text="Gelöscht" textSeverity="error" />}
        {rapport.isArchived && <AbsoluteText text="Archiviert" textSeverity="info" />}
      </Stack>
    </Stack>
  );
};

export default RapportPage;