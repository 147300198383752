import RapportItemHelper from "../helpers/rapport-item-helper";
import { Address, defaultAddress } from "./address";
import { Comments } from "./comment";
import ItemEntry from "./item-entry";
import { defaultJob, Job } from "./job";
import { Material } from "./material";
import { defaultMeasurements, Measurements } from "./measurements";
import { Worktime } from "./worktime";

export type CreateUpdateRapport = {
    projectId: string;
    betreff: string;
    dueDateDate: string;
    dueDateTime: string;
    status: string;
    jobDone: boolean;
    billed: boolean;
    description: string;
    receivedConfirmation: boolean | null;
    assigneeId: number | null;
    jobs: Job;
    measurements: Measurements;
    customers: Address[];
    entries: ItemEntry[];
    comments: Comments[];
    worktimes: Worktime[];
    materials: Material[];
    reporter: string;
    phoneNr: string;
    cost: string;
    counter: string;
    more: string;
    signatureId: number | null;
    signature: string | null;
    vat: number;
    discount: number;
};

export const defaultCreateUpdateRapport: CreateUpdateRapport = {
    projectId: '',
    betreff: '',
    dueDateDate: '',
    dueDateTime: '',
    status: '',
    jobDone: false,
    billed: false,
    description: '',
    receivedConfirmation: null,
    assigneeId: null,
    jobs: defaultJob,
    measurements: defaultMeasurements,
    customers: Array.from({ length: 3 }, () => ({ ...defaultAddress })),
    entries: RapportItemHelper.getAllItems(),
    comments: [],
    worktimes: [],
    materials: [],
    reporter: '',
    phoneNr: '',
    cost: '',
    counter: '',
    more: '',
    signatureId: null,
    signature: null,
    vat: 0,
    discount: 0,
};