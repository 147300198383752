import { Box, Button, IconButton, List, ListItemButton, Skeleton, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { Steiger } from "../models/steiger";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MeContext } from "./Provider/MeContextProvider";
import { RapportContext } from "./Provider/RapportContextProvider";
import { Material } from "../models/material";
import { MetaContext } from "./Provider/MetaContextProvider";
import MaterialService from "../services/material-service";

type SteigerCatalogProps = {
  isXSScreen: boolean;
  searchValue: string;
};

const SteigerCatalog = (props: SteigerCatalogProps) => {
  const context = useContext(MetaContext);
  const rapportContext = useContext(RapportContext);
  const meContext = useContext(MeContext);
  const [allMaterial, setAllMaterial] = useState<Steiger[]>([]);
  const [loadedMaterial, setLoadedMaterial] = useState<Record<string, Steiger[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [parent, setParent] = useState<Steiger | null>(null);

  const catalogType = 'steiger';
  
  const fetchSteigerMaterial = async () => {
    const parentId = parent?.catalogId ?? null;
    console.log(parent, parentId)
    const key = Object.keys(loadedMaterial).find(key => key === `${parentId}`)
    if (!!key) {
      setAllMaterial(loadedMaterial[`${parentId}`]);
      return;
    }

    const data = await context.handleAsyncOperation(
      () => MaterialService.searchSteigerMaterial(parentId ?? undefined)
    );

    if (!!data) {
      setAllMaterial(data);
      setLoadedMaterial(prev => ({ ...prev, [parentId ?? 'null']: data }));
    }
  };

  const fetchSearchedMaterial = async (search?: string) => {
    const data = await context.handleAsyncOperation(
      () => MaterialService.searchSteigerMaterial(undefined, undefined, 50, search)
    );

    if (!!data) {
      setAllMaterial(data);
    }
  };

  const searchMaterial = useCallback(async (value?: string) => {
    if (!!value) {
      await fetchSearchedMaterial(value);
    } else {
      await fetchSteigerMaterial();
    }
  }, [props.searchValue]);

  const handleEntryClick = async (material: Steiger) => {
    if (!material.isMaterial) {
      setParent(material);
    }
  };

  const handleMaterialClick = (evt: React.MouseEvent<HTMLElement>, material: Steiger) => {
    evt.stopPropagation();
    if (material.isMaterial) {
      const newMaterial: Material = { id: 0, catalogId: material.catalogId, displayedProductId: material.catalogId.toString(), catalogType: catalogType, name: material.name, ic: '', amount: 0, price: material.price ?? 0, unit: material.unit ?? "", };
      rapportContext.setRapport(prev => ({...prev, materials: [ ...prev.materials, newMaterial ] }));
    } else {

    }


  };

  const initialize = async () => {
    if (meContext.currentFirm?.hasSteiger) {
      setIsLoading(true);
      await fetchSteigerMaterial();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, [parent, props.isXSScreen]);

  useEffect(() => {
    searchMaterial(props.searchValue);
  }, [props.searchValue]);
  
  const displayEntry = useCallback((steiger: Steiger) => {
    const isParent = steiger.id === parent?.id;
    const imgHeight = 40;
    const imgScale = imgHeight / steiger.height;

    return (
      <Stack>
        <ListItemButton
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            border: 1,
            borderBottom: isParent ? 2 : 1,
            background: isParent ? theme => theme.palette.grey[400] : 'inherit',
          }}
          onClick={() => handleEntryClick(steiger)}
        >
          <Tooltip title={steiger.name}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: isParent ? 0 : 2, alignItems: 'center', flex: 1 }}>
              {(isParent) && (
                <IconButton onClick={(event) => { setParent(null); event.stopPropagation(); }}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              {steiger.isMaterial && (
                <Button onClick={(event) => handleMaterialClick(event, steiger)} variant='outlined'>
                  <Box sx={{ display: 'flex', flexDirection: props.isXSScreen ? 'column' : 'row', gap: props.isXSScreen ? 0 : 0.75 }}>
                    <Typography variant='button'>{steiger.catalogId}</Typography>
                    <Typography variant='button'>hinzufügen</Typography>
                  </Box>
                </Button>
              )}
              <Typography
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  flex: 1,
                  width:  0,
                }}
              >
                {steiger.name}
              </Typography>
            </Box>
          </Tooltip>
          {steiger.imageBlob !== null && (
            <Box sx={{ display: (props.isXSScreen && steiger.isMaterial) ? 'none' : 'flex' }}>
              <img src={URL.createObjectURL(steiger.imageBlob)} width={steiger.width * imgScale} height={imgHeight} />
            </Box>
          )}
        </ListItemButton>
      </Stack>
    );
  }, [parent]);

  return (
    <Box>
      {isLoading ?
        <Stack gap={0.5}>
          {[...Array(2)].map((_, index) => (
            <Skeleton key={index} variant="rectangular" height={53} />
          ))}
        </Stack> :
        <Stack>
          {parent !== null && displayEntry(parent)}
          {allMaterial.map((item, index) =>
            <Box key={index}>
              {displayEntry(item)}
            </Box>
          )}
        </Stack>
      }
    </Box>
  );
};

export default SteigerCatalog;