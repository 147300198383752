import { MaterialDto } from "../dto/material-dto";
import { SteigerDto } from "../dto/catalogs/steiger-dto";
import { Material } from "../models/material";
import { Steiger } from "../models/steiger";
import { base64ToBlob } from "./utils";
import { EldasDto } from "../dto/catalogs/eldas-dto";
import { Eldas } from "../models/eldas";
import { NpkDto } from "../dto/catalogs/npk-dto";
import { Npk } from "../models/npk";

const MaterialHelper = {
  materialToDto: (material: Material): MaterialDto => {
    return {
      id: material.id,
      catalogId: material.catalogId,
      catalogType: material.catalogType,
      displayedProductId: material.displayedProductId,
      name: material.name,
      ic: material.ic,
      amount: material.amount,
      price: material.price,
      unit: material.unit,
    };
  },

  materialFromDto: (materialDto: MaterialDto): Material => {
    return {
      id: materialDto.id,
      catalogId: materialDto.catalogId,
      catalogType: materialDto.catalogType,
      displayedProductId: materialDto.displayedProductId,
      name: materialDto.name,
      ic: materialDto.ic,
      amount: materialDto.amount,
      price: materialDto.price,
      unit: materialDto.unit,
    };
  },

  steigerFromDto: (steigerDto: SteigerDto): Steiger => {
    return {
      id: steigerDto.id,
      catalogId: steigerDto.catalogId,
      parentId: steigerDto.parentId,
      name: steigerDto.name,
      imageBlob: steigerDto.imageBlob ? base64ToBlob(steigerDto.imageBlob, 'image/*') : null,
      width: steigerDto.width,
      height: steigerDto.height,
      isMaterial: steigerDto.isMaterial,
      children: steigerDto.children === null ? [] : steigerDto.children.map(x => MaterialHelper.steigerFromDto(x)),
      price: steigerDto.price,
      unit: steigerDto.unit,
    };
  },

  eldasFromDto: (eldasDto: EldasDto): Eldas => {
    return {
      id: eldasDto.id,
      catalogId: eldasDto.catalogId,
      name: eldasDto.name,
      wareGroup: eldasDto.wareGroup,
      price: eldasDto.price,
      unit: eldasDto.unit,
    };
  },

  npkFromDto: (npkDto: NpkDto): Npk => {
    return {
      id: npkDto.id,
      catalogId: npkDto.catalogId,
      name: npkDto.name,
      parentId: npkDto.parentId,
      svgData: npkDto.svgData,
      isMaterial: npkDto.isMaterial,
      children: npkDto.children,
      price: npkDto.price,
      unit: npkDto.unit,
    };
  },
};

export default MaterialHelper;