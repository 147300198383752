import Dialog from '@mui/material/Dialog';
import { SlideUpTransition } from '../../styles/styles';
import { RapportItemDto } from '../../dto/rapport-item-dto';
import { Stack } from '@mui/material';
import JobsItem from '../Items/JobsItem';
import MaterialItem from '../Items/MaterialItem';
import MeasurementsItem from '../Items/MeasurementsItem';
import AttachmentsItem from '../Items/AttachmentsItem';
import DialogTitlebar from '../DialogTitlebar';
import SignatureItem from '../Items/SignatureItem';
import WorktimeItem from '../Items/WorktimeItem';
import RapportInfoItem from '../Items/RapportInfoItem';

type ItemDialogProps = {
  itemName: string | null;
  itemKey: keyof RapportItemDto | null;
  isOpen: boolean;
  onClose: () => void;
};

const ItemDialog = (props: ItemDialogProps) => {
  const renderItemDialog = () => {
    switch (props.itemKey) {
      case 'rapportInfo':
        return <RapportInfoItem />
      case 'worktime':
        return <WorktimeItem />;
      case 'material':
        return <MaterialItem />
      case 'measurements':
        return <MeasurementsItem />;
      case 'jobs':
        return <JobsItem />;
      case 'attachments':
        return <AttachmentsItem />;
      case 'signature':
        return <SignatureItem />;
    }
  };

  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={SlideUpTransition}
    >
      <DialogTitlebar title={props.itemName ?? ''} onClose={props.onClose}/>
      <Stack sx={{ overflowY: 'auto', padding: { sm: 2 }, paddingTop: '0 !important'}}>
        {renderItemDialog()}
      </Stack>
    </Dialog>
  );
};

export default ItemDialog;
