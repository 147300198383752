import { Stack, Box, Typography, FormControlLabel, Switch, List, ListItemButton, Avatar, Divider, ListItem, IconButton, Input, Chip, Button } from "@mui/material";
import DynamicIcon from "./DynamicIcon";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import RapportItemHelper from "../helpers/rapport-item-helper";
import ItemEntry from "../models/item-entry";

type EditTotalProps = {
  currentItem: ItemEntry | null;
  setCurrentItem: Dispatch<SetStateAction<ItemEntry | null>>;
}

const EditTotal = (props: EditTotalProps) => {
  const [addDiscount, setAddDiscount] = useState(false);
  const [editDiscount, setEditDiscount] = useState(false);
  const [editMWST, setEditMWST] = useState(false);
  const [displayPrices, setDisplayPrices] = useState(false);

  const [materialTotal, setMaterialTotal] = useState(0);
  const [worktimeTotal, setWorktimeTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [mwst, setMWST] = useState(8.1);

  const worktimeItemEntry: ItemEntry = RapportItemHelper.getItemByBeName('worktime')!;
  const materialItemEntry: ItemEntry = RapportItemHelper.getItemByBeName('material')!;

  const updateDiscount = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(evt.target.value);
    setDiscount(isNaN(newValue) ? 0 : newValue);
  };

  const updateMWST = (evt: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(evt.target.value);
    setMWST(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <Stack>
      <Box gap={2} display='flex' justifyContent='space-evenly' alignItems='center'>
        <CreditCardIcon fontSize="large" />
        <Typography sx={{ flex: 1, textAlign: 'center'}} variant='h6'>Gesamtbetrag</Typography>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormControlLabel control={<Switch onChange={() => setDisplayPrices(prev => !prev)} />} label="Preise anzeigen" />
      </Box>
      <List>
        <ListItemButton
          onClick={() => props.setCurrentItem(worktimeItemEntry)}
        >
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Avatar
                sx={{ backgroundColor: theme => theme.palette.primary.main }}
                variant='rounded'
              >
                <DynamicIcon iconName={worktimeItemEntry.iconName} />
              </Avatar>
              <Typography>{worktimeItemEntry.name}</Typography>
            </Box>
            <Box sx={{ display: displayPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>0.00</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItemButton>
        <ListItemButton
          onClick={() => props.setCurrentItem(materialItemEntry)}
        >
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, }}>
              <Avatar
                sx={{ backgroundColor: theme => theme.palette.primary.main }}
                variant='rounded'
              >
                <DynamicIcon iconName={materialItemEntry.iconName} />
              </Avatar>
              <Typography>{materialItemEntry.name}</Typography>
            </Box>
            <Box sx={{ display: displayPrices ? 'flex' : 'none', flexDirection: 'row', gap: 2 }}>
              <Typography>100.00</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItemButton>
        <Divider />
      </List>
      <List sx={{ display: displayPrices ? 'block' : 'none'}}>
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Typography>Netto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>100.00</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
        {addDiscount && (<ListItem>
          <IconButton sx={{ padding: 0, paddingRight: 1}} color="error" onClick={() => setAddDiscount(false)}>
              <HighlightOffIcon fontSize="small" />
          </IconButton>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', margin: 0, gap: 2, alignItems: 'center' }}>
              <Typography>Rabatt</Typography>
              {editDiscount ? (
              <Box>
                <Input
                  autoFocus
                  onChange={updateDiscount}
                  type="numeric"
                  size="small"
                  sx={{ width: 40 }} />%
                  <IconButton onClick={() => setEditDiscount(false)}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
              </Box>
              ) : (
              <Chip
                icon={<EditIcon />}
                onClick={() => setEditDiscount(true)}
                label={discount + '%'}
                size="small"
              />
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>-{discount}</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>)}
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>MWST</Typography>
              {editMWST ? (
              <Box>
                <Input
                  autoFocus
                  onChange={updateMWST}
                  type="numeric"
                  size="small"
                  sx={{ width: 40 }} />%
                  <IconButton onClick={() => setEditMWST(false)}>
                    <CheckIcon fontSize="small" />
                  </IconButton>
              </Box>
              ) : (
              <Chip
                icon={<EditIcon />}
                onClick={() => setEditMWST(true)}
                label={mwst + '%'}
                size="small"
              />
              )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography>{mwst}0</Typography>
              <Typography>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
        <Divider />
        <ListItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
            <Typography fontWeight='bold'>Brutto</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Typography fontWeight='bold'>108.10</Typography>
              <Typography fontWeight='bold'>CHF</Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
      {!addDiscount && <Button sx={{ display: displayPrices ? 'block' : 'none', padding: 0}} onClick={() => setAddDiscount(true)}>Rabatt hinzufügen</Button>}
    </Stack>
  );
};

export default EditTotal;