import { Alert, Avatar, Box, IconButton, List, ListItemButton, Skeleton, Stack, Typography } from "@mui/material";
import { SchochStack } from "../../styles/styles";
import { useDropzone } from "react-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useContext, useEffect, useState } from "react";
import { MetaContext } from "../Provider/MetaContextProvider";
import { RapportContext } from "../Provider/RapportContextProvider";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoText from "../../info/info-text";
import HelpDialog from "../Dialogs/HelpDialog";
import { PDFDocument, PDFForm } from "pdf-lib";
import DeleteIcon from '@mui/icons-material/Delete';
import DynamicIcon from "../DynamicIcon";
import AttachmentService from "../../services/attachment-service";
import { Attachment } from "../../models/attachment";
import { FormInfo } from "../../models/form-info";
import PDFFormViewer from "../PDFComponent";
import { MeContext } from "../Provider/MeContextProvider";

const FormsItem = () => {
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const rapportContext = useContext(RapportContext);
  const {getRootProps, getInputProps} = useDropzone({
    maxSize: 20000000,
    noDrag: true,
    accept: { 'image/*': ['.png', '.jpeg'], 'application/pdf': ['.pdf'] },
    onDropAccepted(files) { dropAccepted(files) },
    onDropRejected() {
      context.setAlertMessage('Die Datei konnte nicht hochgeladen werden. Maximale Grösse: 20 MB, Unterstützte Formate: pdf, png, jpeg');
      context.setAlertSeverity('error');
    },
  });
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const allForms = AttachmentService.getAllForms();

  const dropAccepted = (files: File[]) => {
    const newAttachments: Attachment[] = files.map(file => ({
      id: 0,
      rapportId: rapportContext.rapportId,
      file: file,
      filename: file.name,
      fileBlob: null,
    }));

    rapportContext.setAttachments(prev => ([...prev, ...newAttachments]));
  };

  const convertDateFormat = (date: string | null | undefined) => {
    if (!date) {
      return '';
    }
  
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(date)) {
      return '';
    }
  
    const [year, month, day] = date.split('-');
    const parsedDate = new Date(`${year}-${month}-${day}`);
    if (parsedDate.getFullYear() !== parseInt(year) || 
        parsedDate.getMonth() + 1 !== parseInt(month) || 
        parsedDate.getDate() !== parseInt(day)) {
      return '';
    }
  
    return `${day}.${month}.${year}`;
  }

  const fillBatisec = async (form: PDFForm) => {
    const customer = rapportContext.rapport.customers[1];
    const date = convertDateFormat(rapportContext.rapport.dueDateDate);

    form.getTextField("Text00020").setText(`${customer.firstname} ${customer.lastname}`);
    form.getTextField("Text00002").setText(`${customer.street}`);
    form.getTextField("Text00003").setText(`${customer.postalCode} ${customer.city}`);
    form.getTextField("Text00017").setText(`${date}`);
  };

  const fillSina = async (form: PDFForm) => {
    const customer = rapportContext.rapport.customers[1];
    form.getTextField("Text00026").setText(`${customer.firstname} ${customer.lastname}`);
    form.getTextField("Text00028").setText(`${customer.street}`);
    form.getTextField("Text00031").setText(`${customer.postalCode} ${customer.city}`);

    const location = rapportContext.rapport.customers[0];
    form.getTextField("Text00006").setText(`${location.firstname}`);
    form.getTextField("Text00008").setText(`${location.lastname}`);
    form.getTextField("Text00010").setText(`${location.street}`);
    form.getTextField("Text00012").setText(`${location.postalCode} ${location.city}`);

    const payAddress = rapportContext.rapport.customers[2];
    form.getTextField("Text00007").setText(`${payAddress.firstname}`);
    form.getTextField("Text00009").setText(`${payAddress.lastname}`);
    form.getTextField("Text00011").setText(`${payAddress.street}`);
    form.getTextField("Text00013").setText(`${payAddress.postalCode} ${payAddress.city}`);
  };

  const fillMess = async (form: PDFForm) => {
    const customer = rapportContext.rapport.customers[1];
    form.getTextField("Text00026").setText(`${customer.firstname} ${customer.lastname}`);
    form.getTextField("Text00028").setText(`${customer.street}`);
    form.getTextField("Text00031").setText(`${customer.postalCode} ${customer.city}`);

    const location = rapportContext.rapport.customers[0];
    form.getTextField("Text00006").setText(`${location.firstname}`);
    form.getTextField("Text00008").setText(`${location.lastname}`);
    form.getTextField("Text00010").setText(`${location.street}`);
    form.getTextField("Text00012").setText(`${location.postalCode} ${location.city}`);

    const payAddress = rapportContext.rapport.customers[2];
    form.getTextField("Text00007").setText(`${payAddress.firstname}`);
    form.getTextField("Text00009").setText(`${payAddress.lastname}`);
    form.getTextField("Text00011").setText(`${payAddress.street}`);
    form.getTextField("Text00013").setText(`${payAddress.postalCode} ${payAddress.city}`);
  };

  const fillErst = async (form: PDFForm) => {
    const customer = rapportContext.rapport.customers[1];
    form.getTextField("Text00004").setText(`${customer.firstname} ${customer.lastname}`);
    form.getTextField("Text00003").setText(`${customer.street}`);
    form.getTextField("Text00005").setText(`${customer.postalCode} ${customer.city}`);
  };

  const displayError = () => {
    context.setAlertMessage("Es gab einen Fehler beim Erstellen des Formulars");
    context.setAlertSeverity("error")
  };

  const attachmentFillFunctionMap: Record<number, (form: PDFForm) => void> = {
    1: fillBatisec,
    2: fillSina,
    3: fillMess,
    4: fillErst,
  };

  const loadAndFillPdf = async (formInfo: FormInfo) => {
    const response = await fetch(formInfo.path);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const form = pdfDoc.getForm();

    const fillFunction = attachmentFillFunctionMap[formInfo.id];
    if (fillFunction) {
      fillFunction(form);
    } else {
      displayError();
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.addEventListener('unload', () => {
        URL.revokeObjectURL(url);
      });
    }
  };

  const openFile = (attachment: Attachment) => {
    if (attachment.fileBlob !== null) {
      const fileURL = URL.createObjectURL(attachment.fileBlob);
      window.open(fileURL, '_blank');
    } else if (attachment.file !== null) {
      const fileURL = URL.createObjectURL(attachment.file);
      window.open(fileURL, '_blank');
    }
  };

  const deleteAttachment = async (attachment: Attachment, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const updatedAttachments = rapportContext.attachments.filter(item => item !== attachment);
    rapportContext.setAttachments(updatedAttachments);

    if (attachment.id !== 0) {
      await context.handleAsyncOperation(
        () => AttachmentService.deleteAttachment(attachment.id)
      );
    }
  };

  const fetchAttachment = async () => {
    if (rapportContext.attachmentsLoaded || !!!rapportContext.rapportId) {
      setIsLoading(false);
      return;      
    }

    setIsLoading(true);
    const data = await AttachmentService.getAttachments(rapportContext.rapportId);

    if (!!data) {
      rapportContext.setAttachments(data);
    }

    setIsLoading(false);
    rapportContext.setAttachmentsLoaded(true);
  };

  useEffect(() => {
    fetchAttachment();
  }, []);

  return (
    <Stack sx={{ gap: 2, marginTop: 2 }} >
      <SchochStack>
        <Typography variant="h6">Hochladen</Typography>
        <Box sx={{
          padding: { xs: 4, sm: 8 },
          border: theme => `2px dashed ${theme.palette.grey[400]}`,
          borderRadius: '8px',
          backgroundColor: theme => theme.palette.grey[50],
          textAlign: 'center',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme => theme.palette.grey[200],
          },
        }} {...getRootProps()}>
          <input {...getInputProps()} />
          <AttachFileIcon color="action" sx={{ fontSize: '60px', }}/>
        </Box>
      </SchochStack>
      <SchochStack>
        <Stack>
          <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
            <Typography sx={{ display: { xs: 'none', sm: 'block' } }} variant="h6">Häufig gebrauchte Formulare - Mit Smart Fill</Typography>
            <Typography sx={{ display: { sm: 'none' } }} variant="h6">Häufig gebrauchte Formulare</Typography>
            <IconButton onClick={() => setHelpDialogOpen(true)}><HelpOutlineIcon /></IconButton>
          </Box>
          <List>
            {allForms.map((formData, index) => (
            <ListItemButton key={index} onClick={() => loadAndFillPdf(formData)}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center'}}>
                <Avatar
                  sx={{ backgroundColor: theme => theme.palette.primary.main }}
                  variant='rounded'
                >
                  <DynamicIcon iconName={formData.icon} />
                </Avatar>
                <Typography>{formData.name}</Typography>
              </Box>
            </ListItemButton>
          ))}
          </List>
        </Stack>
      </SchochStack>
      <SchochStack>
        <Stack>
          <Typography variant="h6">Dateien</Typography>
          {isLoading ?
            <Stack gap={2} marginTop={2}>
              {[...Array(3)].map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={40} />
              ))}
            </Stack>
          :
            <Box>
              {(rapportContext.attachments.length > 0) &&
              <List>
                {rapportContext.attachments.map((item, index) => (
                  <ListItemButton key={index} onClick={() => openFile(item)}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1, width: 0 }}>{item.filename}</Typography>
                      <IconButton onClick={(event) => deleteAttachment(item, event)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </ListItemButton>
                ))}
              </List> 
              }
              {(rapportContext.attachments.length === 0) && <Alert severity="info" variant="outlined" sx={{ marginTop: 2 }}>Keine Anhänge hinzugefügt</Alert>}
            </Box> 
          }
        </Stack>
      </SchochStack>
      {meContext.currentUser?.isAdmin && (
        <SchochStack>
          <PDFFormViewer />
        </SchochStack>
      )}
      <HelpDialog title={'Smart Fill'} description={InfoText.smartFill} isOpen={helpDialogOpen} onClose={() => setHelpDialogOpen(false)} />
    </Stack>
  );
};

export default FormsItem;

