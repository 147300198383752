import { Alert, Box, Button, Divider, IconButton, Paper, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import Titlebar from "../components/Titlebar";
import { useCallback, useContext, useEffect, useState } from "react";
import { defaultWorktime, Worktime } from "../models/worktime";
import { MetaContext } from "../components/Provider/MetaContextProvider";
import WorktimeService from "../services/worktime-service";
import { SchochStack } from "../styles/styles";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import WorktimeWeekly from "../components/Charts/WorktimeWeekly";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpDialog from "../components/Dialogs/HelpDialog";
import InfoText from "../info/info-text";
import { addWeeks, differenceInWeeks, endOfWeek, getWeek, isWithinInterval, startOfWeek, subWeeks } from "date-fns";
import WorktimeDialog from "../components/Dialogs/WorktimeDialog";
import { MeContext } from "../components/Provider/MeContextProvider";
import { DeleteDialog } from "../components/Dialogs/Dialog";
import WeeklyDonut from "../components/Charts/WeeklyDonut";
import DynamicIcon from "../components/DynamicIcon";
import WorktimeType, { worktimeOptions } from "../models/worktime-type";
import { WorktimeWeek } from "../models/worktime-week";
import LinkIcon from '@mui/icons-material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatHours } from "../helpers/utils";

const WorktimePage: React.FC = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const initialIndex = 70;

  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);

  const [worktimesUnchecked, setWorktimesUnchecked] = useState<Worktime[]>([]);
  const [newWorktime, setNewWorktime] = useState<Worktime>(defaultWorktime);
  const [helpOpen, setHelpOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openWorktime, setOpenWorktime] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [currentDay, setCurrentDay] = useState<Date>(new Date());
  const [slides, setSlides] = useState<(WorktimeWeek | null)[]>(Array(100).fill(null));
  const [queue, setQueue] = useState<Set<number>>(new Set());
  const [weeklyTarget, setWeeklyTarget] = useState(0);
  const [targetTime, setTargetTime] = useState(0);
  const [openUnchecked, setOpenUnchecked] = useState(false);

  const getIndexByDate = (day: Date): number => {
    const startOfWeek1 = startOfWeek(day, { weekStartsOn: 1 }); // Monday as the start of the week
    const startOfWeek2 = startOfWeek(new Date(), { weekStartsOn: 1 });
    
    const diff = differenceInWeeks(startOfWeek1, startOfWeek2);
    return initialIndex + diff;
  };

  const currentIndex = getIndexByDate(currentDay);
  
  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (slides[currentIndex] === null) {
      loadWeek(currentDay);
    }
  }, [currentDay, slides]);

  useEffect(() => {
    const weekly = (meContext.currentFirm?.weeklyTarget ?? 0) * 60;
    setWeeklyTarget(weekly);
    setTargetTime(Math.round(weekly / 5));
  }, [meContext]);

  const initialize = async () => {
    await Promise.all([
      loadWeek(new Date()),
      loadWeek(addWeeks(new Date(), 1)),
      loadWeek(subWeeks(new Date(), 1)),
      fetchUncheckedWorktimes(),
    ]);
  };

  const loadWeek = useCallback(async (newDay: Date) => {
    const index = getIndexByDate(newDay);
    if (index <= 0 || queue.has(index) || slides[index] !== null) return;

    if (index >= slides.length - 10) {
      setSlides(prev => [...prev, ...Array(10).fill(null)]);
    }

    setQueue(prev => new Set(prev).add(index));
  
    const data = await fetchWeek(getStartOfWeek(newDay));
    setSlides(prev => {
      const updatedSlides = [...prev];
      updatedSlides[index] = data;
      return updatedSlides;
    });

    setQueue(prev => {
      const newQueue = new Set(prev);
      newQueue.delete(index);
      return newQueue;
    });
  }, [slides, queue]);

  const fetchUncheckedWorktimes = async () => {
    const unchecked = await WorktimeService.getUncheckedWorktimes();
    if (!!unchecked) {
      setWorktimesUnchecked(unchecked);
    }
  };

  const getStartOfWeek = (date: Date): Date => {
    return startOfWeek(date, { weekStartsOn: 1 });
  };

  const getDayOfTheWeek = (day: Date): number => {
    return (day.getDay() + 6) % 7; 
  };

  const getEndOfWeek = (date: Date): Date => {
    return endOfWeek(date, { weekStartsOn: 1 });
  };

  const getWeekNumber = (day: Date): number => {
    return getWeek(day, { weekStartsOn: 1, firstWeekContainsDate: 4, })
  };

  const handleCreateClick = (date: Date) => {
    const worktime = { ...defaultWorktime, isChecked: true, user: meContext.currentUser, date: date };
    setNewWorktime(worktime);
    setOpenWorktime(true);
  };

  const handleEditClick = (worktime: Worktime) => {
    setNewWorktime({ ...worktime, user: meContext.currentUser });
    setOpenWorktime(true);
    setSelectedId(worktime.id);
  };

  const handleDeleteClick = (id: number) => {
    setDeleteOpen(true);
    setSelectedId(id);
  };

  const saveWorktime = async (worktime: Worktime) => {
    setOpenWorktime(false);
    
    if (worktime.date === null) {
      return;
    }

    if (!!!worktime.id) { // Info: if id is undefined or 0
      await context.handleAsyncOperation(
        () => WorktimeService.addWorktime(worktime)
      )
    } else {
      await context.handleAsyncOperation(
        () => WorktimeService.updateWorktime(worktime.id, worktime)
      )
      setSelectedId(null);
    }

    refreshWeek(worktime.date);
  };

  const deleteWorktime = async () => {
    if (selectedId === null) return;
    const idToDelete = selectedId;
    setSelectedId(null);
    setDeleteOpen(false);

    await context.handleAsyncOperation(
      () => WorktimeService.deleteWorktime(idToDelete)
    );

    refreshWeek(currentDay);
  };

  const checkWorktime = async (id: number) => {
    setWorktimesUnchecked(prev => prev.filter(x => x.id !== id));
    
    await context.handleAsyncOperation(
      () => WorktimeService.checkWorktime(id)
    );
  };

  const copyWorktime = async (worktime: Worktime) => {
    setWorktimesUnchecked(prev => prev.filter(x => x.id !== worktime.id));

    const worktimeCopy = { ...worktime, id: 0, isChecked: true, copyId: worktime.id };
    await context.handleAsyncOperation(
      () => WorktimeService.updateWorktime(0, worktimeCopy)
    );

    if (worktimeCopy.date !== null) {
      refreshWeek(worktimeCopy.date);
    }
  };

  const setToToday = () => {
    setCurrentDay(new Date());
  }

  const refreshWeek = (day: Date) => {
    const changeIndex = getIndexByDate(day);
    
    setSlides(prev => {
      const updatedSlides = [...prev];
      updatedSlides[changeIndex] = null;
      return updatedSlides;
    });
    
    if (currentIndex === changeIndex) {
      loadWeek(day);
    }
  };

  const fetchWeek = async (startOfWeek: Date) => {
    return WorktimeService.getWeek(startOfWeek);
  };

  const handleSlideChange = useCallback((isIncreased: boolean) => {
    const newDay = addWeeks(currentDay, isIncreased ? 1 : -1);
    const newIndex = getIndexByDate(newDay);
    if (newIndex <= 0) return;

    loadWeek(newDay);
    loadWeek(addWeeks(newDay, 1));
    loadWeek(subWeeks(newDay, 1));

    setCurrentDay(newDay);
  }, [loadWeek, currentDay]);

  const renderIcon = (type: WorktimeType) => {
    const option = worktimeOptions.find(w => w.key === type);
    if (!!!option) {
      return;
    }
    
    return (
      <Tooltip title={option.display}>
        <Box>
          <DynamicIcon iconName={option.iconName} />
        </Box>
      </Tooltip>
    );
  };

  const displayUnchecked = () => {
    return (
      <SchochStack>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} onClick={() => setOpenUnchecked(prev => !prev)}>
          <Typography variant="h6">Unbestätigte Arbeitszeit ({worktimesUnchecked.length})</Typography>
          <Box>
            <IconButton onClick={(event) => {event.stopPropagation(); setHelpOpen(true)}}>
              <HelpOutlineIcon />
            </IconButton>
            <IconButton>
              {openUnchecked ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </Box>
        {openUnchecked && <Box>
          {worktimesUnchecked.map((w, index) => (
            <Stack key={index}>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 2, paddingTop: 1, paddingBottom: 1 }}>
                <LinkIcon />
                <Typography fontWeight='bold'>{w.date?.toLocaleDateString('de-DE', { day: '2-digit', month:  isXs ? 'short' : 'long' }) ?? "(Kein Datum)"}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'space-between', flex: 1 }}>
                  <Box flex={1} width={0}>
                    <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      {isXs ? "" : (!!w.description ? w.description : "(Keine Beschreibung)")}
                    </Typography>
                  </Box>
                  <Typography fontWeight='bold'>{formatHours(w.timeInHours)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Divider flexItem orientation="vertical" />
                  <IconButton color="primary" onClick={() => { copyWorktime(w); checkWorktime(w.id); }}>
                    <CheckIcon />
                  </IconButton>
                  <Divider flexItem orientation="vertical" />
                  <IconButton onClick={() => checkWorktime(w.id)}>
                    <ClearIcon />
                  </IconButton>
                </Box>
              </Box>
              {(index !== worktimesUnchecked.length - 1) && <Divider orientation="horizontal" />}
            </Stack>
          ))}
        </Box>}
      </SchochStack>
    );
  };

  const displayWeekly = () => {
    const overtime = 0;
    const total = (slides[currentIndex]?.total || 0);
    const work = (slides[currentIndex]?.days.reduce((acc, x) => acc + x.workInMinutes, 0) || 0);
    const compensation = (slides[currentIndex]?.days.reduce((acc, x) => acc + x.compensationInMinutes, 0) || 0);
    const vacation = (slides[currentIndex]?.days.reduce((acc, x) => acc + x.vacationInMinutes, 0) || 0);
    const unpaid = (slides[currentIndex]?.days.reduce((acc, x) => acc + x.unpaidInMinutes, 0) || 0);
    const sickness = (slides[currentIndex]?.days.reduce((acc, x) => acc + x.sicknessInMinutes, 0) || 0);
    const remaining = weeklyTarget - total;

    return (
      <SchochStack>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h6">Woche {getWeekNumber(currentDay)} - {currentDay.getFullYear()}</Typography>
          <IconButton onClick={() => setHelpOpen(true)}>
              <HelpOutlineIcon />
            </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', sm: 'space-between' }, gap: 2 }}>
          <Stack sx={{ display: { xs: 'none', sm: 'flex' }, flex: 1, gap: 2 }}>
            <Paper sx={{ padding: 2, borderRadius: 0 }}>
              <Typography textAlign='center' variant="subtitle2">Wöchentliches Total</Typography>
              <Typography textAlign='center' variant="h6">{formatHours(total / 60)}</Typography>
            </Paper>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, }}>
              <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
                <Typography textAlign={'center'} variant="subtitle2">Überzeit-Guthaben</Typography>
                <Typography textAlign='center' variant="h6">{formatHours(overtime)}</Typography>
              </Paper>
              <Paper sx={{ padding: 2, flex: 1, borderRadius: 0 }}>
                <Typography textAlign={'center'} variant="subtitle2">Ferien-Guthaben</Typography>
                <Typography textAlign='center' variant="h6">{formatHours(0)}</Typography>
              </Paper>
            </Box>
            <Paper sx={{ padding: 2, borderRadius: 0, display: 'flex', justifyContent: 'center', gap: 2, }}>
              <Button variant="contained" onClick={setToToday}>Heute</Button>
            </Paper>
          </Stack>
          <Box>
            <WeeklyDonut remaining={remaining} unpaid={unpaid} compensation={compensation} worktime={work} vacation={vacation} sickness={sickness} target={weeklyTarget} />
          </Box>
        </Box>
      </SchochStack>
    );
  };

  const displayChart = () => {
    const weeklyData = (slides[currentIndex] !== null) ? slides[currentIndex]!.days.map(x => x.timeInMinutes) : [ 0, 0, 0, 0, 0, 0, 0 ]; 

    return (
      <SchochStack>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => handleSlideChange(false)}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={isWithinInterval(new Date(), { start: getStartOfWeek(currentDay), end: getEndOfWeek(currentDay) }) ? 'bold' : '' }>{getStartOfWeek(currentDay).toLocaleDateString('de-DE', { day: '2-digit', month:  isXs ? 'short' : 'long' })} - {getEndOfWeek(currentDay).toLocaleDateString('de-DE', { day: '2-digit', month:  isXs ? 'short' : 'long' })}</Typography>
          <IconButton onClick={() => handleSlideChange(true)}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
        <Box sx={{ height: isXs ? 215 : 365 }} height={215}>
          {queue.has(currentIndex) ?
          <Box display='flex' gap={1}>
            <Box width='10%'>
              <Skeleton sx={{ height: isXs ? 215 : 365 }} variant="rectangular" />
            </Box>
            <Stack flex={1} gap={1}>
              <Skeleton sx={{ height: '80%' }} variant="rectangular" />
              <Skeleton sx={{ height: '20%' }} variant="rectangular" />
            </Stack>
          </Box> : 
          <WorktimeWeekly height={isXs ? 215 : 365} targetTime={targetTime} workHours={weeklyData} currentDay={currentDay} setCurrentDay={setCurrentDay} createWorktime={handleCreateClick} />}
        </Box>
      </SchochStack>
    );
  };

  const displayDaily = () => {
    if (slides[currentIndex] === null) {
      return (
        <SchochStack>
          <Skeleton height={32} />
          <Skeleton height={50} variant="rectangular" />
        </SchochStack>
      );
    }

    const index = getDayOfTheWeek(currentDay);
    const worktime = slides[currentIndex]!.days[index];

    return (
      <SchochStack>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6">{currentDay.toLocaleDateString('de-DE', { day: '2-digit', month: 'long', year: 'numeric' })}</Typography>
      </Box>
      <Box>
        {(worktime?.worktimes.length === 0) && <Alert severity="info" variant="outlined">Keine Zeitangaben gefunden</Alert>}
        {worktime?.worktimes.map((w, index) => (
          <Stack key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingTop: 1, paddingBottom: 1, background: (selectedId === w.id) ? theme => theme.palette.primary.lighter : '' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, width: '100%', paddingRight: 2, alignItems: 'center' }}>
                {renderIcon(w.type)}
                <Box sx={{ flex: 1, width: 0 }}>
                  <Typography sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {!!w.description ? w.description : "(Keine Beschreibung)"}
                  </Typography>
                </Box>
                <Typography fontWeight='bold'>{formatHours(w.timeInHours)}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
                <Box display='flex' flexDirection='row'>
                  <Divider flexItem orientation="vertical" />
                  {w.copyId !== null ?
                    <IconButton disabled>
                      <LinkIcon />
                    </IconButton> :
                    <IconButton onClick={() => handleEditClick(w)}>
                      <EditIcon />
                    </IconButton>
                  }
                  <Divider flexItem orientation="vertical" />
                  <IconButton onClick={() => handleDeleteClick(w.id)}>
                    <DeleteIcon/>
                  </IconButton>
                </Box>
              </Box>
            </Box>
            {(index !== worktime?.worktimes.length - 1) && <Divider orientation="horizontal" />}
          </Stack>
        ))}
      </Box>
    </SchochStack>
    );
  };

  return (
    <Stack>
      <Titlebar title="Zeiterfassung" iconName="AccessTime" />
      <Stack sx={{ padding: { sm: 2 }, gap: 2 }}>
        {(worktimesUnchecked.length > 0) && displayUnchecked()}
        {displayWeekly()}
        {displayChart()}
        {displayDaily()}
      </Stack>
      <WorktimeDialog worktime={newWorktime} index={-1} lockedName={true} isOpen={openWorktime} onSave={saveWorktime} onClose={() => {setOpenWorktime(false); setSelectedId(null);}} />
      <HelpDialog title={"Arbeitszeit übernehmen"} description={InfoText.uncheckedWorktime} isOpen={helpOpen} onClose={() => setHelpOpen(false)} />
      <DeleteDialog title="Löschen" description="Wollen Sie den ausgewählten Eintrag aus der Zeiterfassung löschen?" isOpen={deleteOpen} onClose={() => { setDeleteOpen(false); setSelectedId(null); }} onConfirm={deleteWorktime} />
    </Stack>
  );
};

export default WorktimePage;