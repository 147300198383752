import RapportAPI from "../api/rapport-api";
import { PostItDto } from "../dto/post-it-dto";
import CommentHelper from "../helpers/comment-helper";
import LocalStorageHelper from "../helpers/local-storage-helper";
import RapportHelper from "../helpers/rapport-helper";
import { Comments } from "../models/comment";
import { CreateUpdateRapport } from "../models/create-update-rapport";
import { Rapport } from "../models/rapport";
import { RapportFilter } from "../models/rapport-filter";

const RapportService = {
  syncQuarantine: async (): Promise<string[]> => {
    return await RapportAPI.syncQuarantine();
  },

  exportQuarantine: async (id: string) => {
    await RapportAPI.exportQuarantine(id);
  },

  sendPostItQuarantine: async (id: string, message: string): Promise<boolean> => {
    const postIt: PostItDto = { message: message };
    return await RapportAPI.sendPostItQuarantine(id, postIt);
  },

  getRapports: async (page?: number, pageSize?: number, search?: string, jobDone?: boolean, billed?: boolean, paperbin?: boolean, archive?: boolean, assignedToMe?: boolean): Promise<Rapport[] | null> => {
    const rapportDtos = await RapportAPI.getRapports(page, pageSize, search, jobDone, billed, paperbin, archive, assignedToMe);
    if (!!!rapportDtos) {
      return null;
    }
    
    return rapportDtos.map((rapportDto) =>
      RapportHelper.rapportFromDto(rapportDto)
    );
  },

  getRapport: async (id: string): Promise<Rapport | null> => {
    const rapportDto = await RapportAPI.getRapport(id);
    if (!rapportDto) {
      return null;
    }

    return RapportHelper.rapportFromDto(rapportDto);
  },

  getRapportUpdatable: async (id: string): Promise<CreateUpdateRapport | null> => {
    const rapportDto = await RapportAPI.getRapport(id);
    if (!rapportDto) {
      return null;
    }

    return RapportHelper.updateRapportFromRapport(rapportDto);
  },

  calculateRapportTotal: async (id: string) => {
    return await RapportAPI.calculateRapportTotal(id);
  },

  createRapport: async (rapport: CreateUpdateRapport): Promise<string> => {
    return await RapportAPI.createRapport(RapportHelper.createUpdateRapportToDto(rapport));
  },

  copyRapport: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.copyRapport(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },

  followUpRapport: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.followUpRapport(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },

  updateRapport: async (id: string, rapport: CreateUpdateRapport): Promise<string> => {
    return await RapportAPI.updateRapport(id, RapportHelper.createUpdateRapportToDto(rapport));
  },

  deleteRapport: async (id: string) => {
    await RapportAPI.deleteRapport(id);
  },

  getRapportCount: async (): Promise<number> => {
    return await RapportAPI.getRapportCount();
  },

  toggleRapportJobDone: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.toggleRapportJobDone(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },

  toggleRapportBilled: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.toggleRapportBilled(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },

  toggleRapportDeleted: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.toggleRapportDeleted(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },

  toggleRapportArchived: async (id: string): Promise<Rapport> => {
    const rapportDto = await RapportAPI.toggleRapportArchived(id);
    return RapportHelper.rapportFromDto(rapportDto);
  },
  
  updateComment: async (id: string, comment: Comments): Promise<number> => {
    const commentDto = CommentHelper.commentToDto(comment);
    return await RapportAPI.updateComment(id, commentDto);
  },

  resetFilter: () => {
    LocalStorageHelper.removeRapportFilter();
  },

  setFilter: (filter: RapportFilter) => {
    LocalStorageHelper.setRapportFilter(filter);
  },

  getFilter: (): RapportFilter | null => {
    return LocalStorageHelper.getRapportFilter();
  },
}

export default RapportService;