import { CreateUpdateRapportDto } from "../dto/create-update-rapport-dto";
import { RapportDto } from "../dto/rapport-dto";
import { CreateUpdateRapport } from "../models/create-update-rapport";
import { Rapport } from "../models/rapport";
import AddressHelper from "./address-helper";
import CommentHelper from "./comment-helper";
import JobHelper from "./job-helper";
import MaterialHelper from "./material-helper";
import MeasurementsHelper from "./measurements-helper";
import RapportItemHelper from "./rapport-item-helper";
import UserHelper from "./user-helper";
import WorktimeHelper from "./worktime-helper";

const RapportHelper = {
  createUpdateRapportToDto: (rapport: CreateUpdateRapport): CreateUpdateRapportDto => {
    const dueDate = new Date(rapport.dueDateDate);
    if (!!rapport.dueDateTime) {
      const [hours, minutes] = rapport.dueDateTime.split(":").map(Number);
      dueDate.setUTCHours(hours, minutes, 0, 0);
    }

    const rapportDto: CreateUpdateRapportDto = {
      projectId: rapport.projectId,
      betreff: rapport.betreff,
      dueDate: dueDate,
      location: AddressHelper.addressToDto(rapport.customers[1]),
      customer: AddressHelper.addressToDto(rapport.customers[0]),
      billingAddress: AddressHelper.addressToDto(rapport.customers[2]),
      status: rapport.status,
      jobDone: rapport.jobDone,
      billed: rapport.billed,
      description: rapport.description,
      receivedConfirmation: rapport.receivedConfirmation,
      assigneeId: rapport.assigneeId,
      measurements: MeasurementsHelper.measurementsToDto(rapport.measurements),
      jobs: JobHelper.jobToDto(rapport.jobs),
      rapportItems: RapportItemHelper.itemEntriesToRapportItem(rapport.entries),
      worktimes: rapport.worktimes.map(x => WorktimeHelper.worktimeToDto(x)),
      materials: rapport.materials.map(x => MaterialHelper.materialToDto(x)),
      reporter: rapport.reporter,
      phoneNr: rapport.phoneNr,
      cost: rapport.cost,
      counter: rapport.counter,
      more: rapport.more,
      signatureId: rapport.signatureId,
      signature: rapport.signature,
      vat: rapport.vat,
      discount: rapport.discount,
    };

    return rapportDto;
  },
  
  updateRapportFromRapport: (rapportDto: RapportDto): CreateUpdateRapport => {
    let dueDateDate = '';
    let dueDateTime = '';
    
    if (rapportDto.dueDate !== null) {
      const dueDate = new Date(rapportDto.dueDate);
      dueDateDate = dueDate.toLocaleDateString('sv-SE');
      dueDateTime = dueDate.toLocaleTimeString();
    }

    return {
      projectId: rapportDto.projectId,
      betreff: rapportDto.betreff,
      dueDateTime: dueDateTime,
      dueDateDate: dueDateDate,
      status: rapportDto.status,
      jobDone: rapportDto.jobDone,
      billed: rapportDto.billed,
      description: rapportDto.description,
      receivedConfirmation: rapportDto.receivedConfirmation,
      assigneeId: rapportDto.assigneeId,
      measurements: MeasurementsHelper.measurementsFromDto(rapportDto.measurements),
      jobs: JobHelper.jobFromDto(rapportDto.jobs),
      customers: [rapportDto.customer, rapportDto.location, rapportDto.billingAddress],
      entries: RapportItemHelper.itemEntriesFromRapportItem(rapportDto.rapportItems),
      comments: rapportDto.comments.map(x => CommentHelper.commentFromDto(x)),
      worktimes: rapportDto.worktimes.map(x => WorktimeHelper.worktimeFromDto(x)),
      materials: rapportDto.materials.map(x => MaterialHelper.materialFromDto(x)),
      reporter: rapportDto.reporter,
      phoneNr: rapportDto.phoneNr,
      cost: rapportDto.cost,
      counter: rapportDto.counter,
      more: rapportDto.more,
      signatureId: rapportDto.signatureId,
      signature: rapportDto.signature,
      vat: rapportDto.vat,
      discount: rapportDto.discount,
    };
  },

  rapportFromDto: (rapportDto: RapportDto): Rapport => {
    return {
      id: rapportDto.id,
      emilId: rapportDto.emilId,
      projectId: rapportDto.projectId,
      betreff: rapportDto.betreff,
      dueDate: (rapportDto.dueDate !== null) ? new Date(rapportDto.dueDate) : null,
      status: rapportDto.status,
      jobDone: rapportDto.jobDone,
      billed: rapportDto.billed,
      isDeleted: rapportDto.isDeleted,
      isArchived: rapportDto.isArchived,
      assigneeId: rapportDto.assigneeId,
      measurements: MeasurementsHelper.measurementsFromDto(rapportDto.measurements),
      customers: [rapportDto.customer, rapportDto.location, rapportDto.billingAddress],
      entries: RapportItemHelper.itemEntriesFromRapportItem(rapportDto.rapportItems),
      description: rapportDto.description,
      receivedConfirmation: rapportDto.receivedConfirmation,
      jobs: JobHelper.jobFromDto(rapportDto.jobs),
      creator: rapportDto.creator ? UserHelper.userFromDto(rapportDto.creator) : null,
      createDate: (rapportDto.createDate !== null) ? new Date(rapportDto.createDate) : null,
      comments: rapportDto.comments.map(x => CommentHelper.commentFromDto(x)),
      worktimes: rapportDto.worktimes.map(x => WorktimeHelper.worktimeFromDto(x)),
      materials: rapportDto.materials.map(x => MaterialHelper.materialFromDto(x)),
      reporter: rapportDto.reporter,
      phoneNr: rapportDto.phoneNr,
      cost: rapportDto.cost,
      counter: rapportDto.counter,
      more: rapportDto.more,
      signature: rapportDto.signature,
      vat: rapportDto.vat,
      discount: rapportDto.discount,
    };
  },

  isValidDate: (dateString: string): boolean => {
    const year = new Date(dateString).getFullYear();
    return !isNaN(year) && year >= 1970 && year <= 9999;
  },
}

export default RapportHelper;