import { createContext, useState, ReactNode, useEffect, SetStateAction, Dispatch, useContext } from "react";
import UserService from "../../services/user-service";
import { User } from "../../models/user";
import { Firm } from "../../models/firm";
import UserFirmService from "../../services/user-firm-service";
import { MetaContext } from "./MetaContextProvider";
import { CatalogType } from "../../models/catalog-type";
import FirmService from "../../services/firm-service";
import { AuthContext } from "./AuthContextProvider";

type MeContextType = {
  isLoading: boolean,
  currentUser: User | null,
  updateUser: (firstname: string, lastname: string, username: string) => void,
  currentFirm: Firm | null,
  setCurrentFirm: Dispatch<SetStateAction<Firm | null>>,
  syncCatalogLicences: () => Promise<void>,
  currentCatalog: CatalogType | "",
  setCurrentCatalog: Dispatch<SetStateAction<CatalogType | "">>,
  userLicence: string | null,
  setUserLicence: Dispatch<SetStateAction<string | null>>,
  firmUsers: User[] | null,
  setFirmUsers: Dispatch<SetStateAction<User[] | null>>,
};

export const MeContext = createContext<MeContextType>({
  isLoading: true,
  currentUser: null,
  updateUser: () => {},
  currentFirm: null,
  setCurrentFirm: () => {},
  syncCatalogLicences: async () => {},
  currentCatalog: "",
  setCurrentCatalog: () => {},
  userLicence: null,
  setUserLicence: () => {},
  firmUsers: null,
  setFirmUsers: () => {},
});

type MeContextProviderProps = {
  children: ReactNode;
};

const MeContextProvider = (props: MeContextProviderProps) => {
  const context = useContext(MetaContext);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentFirm, setCurrentFirm] = useState<Firm | null>(null);
  const [currentCatalog, setCurrentCatalog] = useState<CatalogType | "">("");
  const [userLicence, setUserLicence] = useState<string | null>(null);
  const [firmUsers, setFirmUsers] = useState<User[] | null>(null);

  const fetchCurrentUser = async () => {
    const user = await context.handleAsyncOperation(
      UserService.getCurrentUser
    );

    if (!!user) {
      setCurrentUser(user);
    }
  };

  const fetchCurrentFirm = async () => {   
    try {
      const firm = await context.handleAsyncOperation(
        UserFirmService.getFirmOfUser
      );
  
      if (!!firm) {
        setCurrentFirm(firm);
      }
    } catch {
      setCurrentFirm(null);
    }
  };

  const fetchUserLicence = async () => {
    const userLicence = await context.handleAsyncOperation(
      UserFirmService.getUserLicence
    );

    !!userLicence ? setUserLicence(userLicence) : setUserLicence(null);
  };

  const fetchUsersOfFirm = async () => {
    const firmUsers = await context.handleAsyncOperation(
      UserFirmService.getAllUsers
    );

    if (!!firmUsers) {
      setFirmUsers(firmUsers);
    }
  };

  const updateUser = async (firstname: string, lastname: string, username: string) => {
    const id = await UserService.updateUser(firstname, lastname, username);
    if (id === null) {
      context.setAlertMessage("Ein Fehler ist aufgetreten.");
      context.setAlertSeverity("error");
      return;
    }

    context.setAlertMessage("Die Änderungen wurden erfolgreich gespeichert.");
    context.setAlertSeverity("success");
    setCurrentUser(prev => ({...prev!, firstName: firstname, lastName: lastname, username: username}));
  };

  const syncCatalogLicences = async () => {
    const data = await context.handleAsyncOperation(
      FirmService.syncCatalogLicences
    );

    if (!!data) {
      setCurrentFirm(data);
      context.setAlertMessage("Die Lizenzen wurden erfolgreich geprüft.");
      context.setAlertSeverity("success");
    }
  };

  const reset = () => {
    setCurrentUser(null);
    setCurrentFirm(null);
    setUserLicence(null);
    setCurrentCatalog("");
  };

  const initialize = async () => {
    await Promise.all([
      fetchCurrentUser(),
      fetchCurrentFirm(),
      fetchUserLicence(),
      fetchUsersOfFirm(),
    ]);
  };

  useEffect(() => {
    try {
      setIsLoading(true);
      if (authContext.isAuthenticated) {
        initialize();
      } else {
        reset();
      }
    } finally {
      setIsLoading(false);
    }
  }, [authContext]);

  return (
    <MeContext.Provider
      value={{
        isLoading,
        currentUser,
        updateUser,
        currentFirm,
        setCurrentFirm,
        syncCatalogLicences,
        currentCatalog,
        setCurrentCatalog,
        userLicence,
        setUserLicence,
        firmUsers,
        setFirmUsers,
      }}
    >
      {props.children}
    </MeContext.Provider>
  );
}

export default MeContextProvider;