import { Stack, Typography, Box, Divider, IconButton, PopoverPosition, Theme, Tooltip, } from "@mui/material";
import { Rapport } from "../models/rapport";
import PaidIcon from '@mui/icons-material/Paid';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { MetaContext } from "./Provider/MetaContextProvider";
import RapportListContextmenu from "./Contextmenu/RapportListContextmenu";
import { useNavigate } from "react-router-dom";
import RapportService from "../services/rapport-service";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import SendIcon from '@mui/icons-material/Send';
import { DeleteDialog } from "./Dialogs/Dialog";
import { MeContext } from "./Provider/MeContextProvider";
import PostItDialog from "./Dialogs/PostItDialog";

type RapportListEntryProps = {
  archive: boolean;
  paperbin: boolean;
  rapport: Rapport;
  updateRapport: (rapport: Rapport) => void;
  setRapports: Dispatch<SetStateAction<Rapport[]>>;
};

const RapportListEntry = (props: RapportListEntryProps) => {
  const navigate = useNavigate();
  const context = useContext(MetaContext);
  const meContext = useContext(MeContext);
  const [anchorEl, setAnchorEl] = useState<PopoverPosition | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isPostItOpen, setIsPostItOpen] = useState(false);

  const handleReceivedConfirmationClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsPostItOpen(true);
  };

  const handleJobDoneClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await toggleJobDone();
  };

  const toggleJobDone = async () => {
    const updatedRapport = await context.handleAsyncOperation(
      () => RapportService.toggleRapportJobDone(props.rapport?.id!),
    ) as Rapport;
    if (!!updatedRapport) {
      props.updateRapport(updatedRapport);
    }
  };

  const handleBilledClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await toggleBilled();
  };

  const toggleBilled = async () => {
    const updatedRapport = await context.handleAsyncOperation(
      () => RapportService.toggleRapportBilled(props.rapport?.id!)
    ) as Rapport;
    if (!!updatedRapport) {
      props.updateRapport(updatedRapport);
    }
  };

  const copyRapport = async () => {
    const copiedRapport = await context.handleAsyncOperation(
      () => RapportService.copyRapport(props.rapport?.id!)
    );

    if (!!copiedRapport) {
      props.setRapports((rapports) => [...rapports, copiedRapport]);
    }
  };

  const followUpRapport = async () => {
    const newRapport = await context.handleAsyncOperation(
      () => RapportService.followUpRapport(props.rapport?.id!)
    );

    if (!!newRapport) {
      props.setRapports((rapports) => [...rapports, newRapport]);
    }
  };

  const restoreRapport = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      context.setIsLoading(true);
      await RapportService.toggleRapportDeleted(props.rapport.id);
      props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport.id));
      context.setAlertMessage("Der Rapport wurde erfolgreich wiederhergestellt.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Wiederherstellen des Rapports ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const unarchiveRapport = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    try {
      context.setIsLoading(true);
      await RapportService.toggleRapportArchived(props.rapport.id);
      props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport.id));
      context.setAlertMessage("Der Rapport wurde erfolgreich aus dem Archiv verschoben.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Wiederherstellen des Rapports ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const handleEditClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    navigate(`/rapport/${props.rapport.id}/edit`);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl({ top: event.clientY - 6, left: event.clientX + 2 });
  };

  const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsDeleteOpen(true);
  };

  const handleDeleteForeverClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const deleteForever = async () => {
    try {
      context.setIsLoading(true);
      await RapportService.deleteRapport(props.rapport.id);
      props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport.id));
      context.setAlertMessage("Der Rapport wurde erfolgreich gelöscht.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Löschen ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const deleteRapport = async () => {
    try {
      context.setIsLoading(true);
      await RapportService.toggleRapportDeleted(props.rapport.id);
      await RapportService.toggleRapportArchived(props.rapport.id);
      props.setRapports((rapports) => rapports.filter((rapport) => rapport.id !== props.rapport.id));
      context.setAlertMessage("Der Rapport wurde in den Papierkorb verschoben.");
      context.setAlertSeverity("success");
    } catch (error) {
      context.setAlertMessage("Beim Löschen ist ein Fehler aufgetreten.");
      context.setAlertSeverity("error");
      console.error(error);
    } finally {
      context.setIsLoading(false);
    }
  };

  const standardButtons = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }} >
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 1, }}>
          {props.rapport.receivedConfirmation === false && <Divider orientation="vertical" flexItem />}
          {props.rapport.receivedConfirmation === false && <Tooltip title="Empfangsbestätigung senden">
            <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleReceivedConfirmationClick}>
              <SendIcon fontSize='large' color='action' />
            </IconButton>
          </Tooltip>}
          <Divider orientation="vertical" flexItem />
          <Tooltip title="Arbeit erledigt?">
            <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleJobDoneClick}>
              <HomeRepairServiceIcon fontSize='large' color={props.rapport.jobDone ? 'primary' : 'action'} />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
          <Tooltip title="Verrechnet?">
          <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleBilledClick}>
            <PaidIcon fontSize='large' color={props.rapport.billed ? 'primary' : 'action'} />  
          </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { sm: 1 }, paddingLeft: { sm: 1 } }}>
          <Tooltip title="Bearbeiten">
            <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleEditClick}>
              <EditIcon fontSize='large' />
            </IconButton>
          </Tooltip>
          <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} flexItem />
          <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleMenuClick}>
            <MoreHorizIcon fontSize='large' />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const paperbinButtons = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { sm: 1 } }} >
        <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />
        <IconButton sx={{ borderRadius: 1 }} size='large' onClick={restoreRapport}>
          <RestoreFromTrashIcon fontSize='large' />  
        </IconButton>
        <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />
          <Divider sx={{ display: { xs: 'block', sm: 'none' } }} flexItem />
        <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleDeleteForeverClick}>
          <DeleteForeverIcon fontSize='large' />
        </IconButton>
      </Box>
    );
  };

  const archiveButtons = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { sm: 1 } }} >
        <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />
        <IconButton sx={{ borderRadius: 1 }} size='large' onClick={unarchiveRapport}>
          <UnarchiveIcon fontSize='large' />  
        </IconButton>
        <Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />
        <Divider sx={{ display: { xs: 'block', sm: 'none' } }} flexItem />
        <IconButton sx={{ borderRadius: 1 }} size='large' onClick={handleDeleteClick}>
          <DeleteIcon fontSize='large' />
        </IconButton>
      </Box>
    );
  };

  const backgroundColor = (theme: Theme) => {
    return (context.selectedRapportIds.includes(props.rapport.id)
      ? theme.palette.primary.lighter
      : theme.palette.grey[100]);
  };

  const highlightColor = (theme: Theme) => {
    return (meContext.currentUser?.isAdmin ? // Todo: Remove Admin condition
      ((meContext.currentUser?.id === props.rapport.assigneeId)
        ? theme.palette.primary.main
        : theme.palette.grey[500]) :
      '');
  };

  return (
    <Box>
      <Stack sx={{ border: 1, borderTop: 0, flex: 1, padding: 2, gap: 2, 
          backgroundColor: theme => backgroundColor(theme),
          ...(props.rapport.assigneeId !== null) && { background: theme => `linear-gradient(to bottom right, ${highlightColor(theme)} 0%, ${backgroundColor(theme)} 25%)` }
      }} onClick={() => navigate(`/rapport/${props.rapport?.id}`)}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
          <Typography>{props.rapport.emilId}</Typography>
          <Typography sx={{fontWeight:'bold', whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden', flex:'1'}}>{props.rapport.betreff !== '' ? props.rapport.betreff : "(Kein Betreff)"}</Typography>
          {props.rapport.status !== "" ? <Typography>Status: {props.rapport.status}</Typography> : <Typography fontStyle="italic">{"(Kein Status)"}</Typography>}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }} >
              {(props.rapport.dueDate !== null && props.rapport.dueDate.getTime() > 0) ? <Stack>
                <Typography variant='caption'>{props.rapport.dueDate.toLocaleDateString('de-DE', { weekday: 'long' })},</Typography>
                <Typography variant='caption'>{props.rapport.dueDate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: '2-digit' })}</Typography>
                <Typography variant='caption'>{props.rapport.dueDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Typography>
              </Stack> : <Typography fontStyle='italic'>{"(Kein Datum)"}</Typography>}
              <Divider orientation="vertical" flexItem />
            </Box>
            {props.rapport.customers[1].city !== '' || props.rapport.customers[1].postalCode !== '' ?
              <Stack flex='1' overflow='hidden'>
                <Typography variant='caption'>{props.rapport.customers[1].firstname} {props.rapport.customers[1].lastname}</Typography>
                <Typography variant='caption'>{props.rapport.customers[1].postalCode} {props.rapport.customers[1].city}</Typography>
                <Typography variant='caption'>{props.rapport.customers[1].street}</Typography>
              </Stack> : <Typography alignSelf='center' fontStyle='italic'>{"(Keine Adresse)"}</Typography>
            }
          </Box>
          {props.paperbin ? paperbinButtons() : (props.archive ? archiveButtons() : standardButtons()) }
        </Box>
      </Stack>
      <RapportListContextmenu rapport={props.rapport} setRapports={props.setRapports} toggleJobDone={toggleJobDone} toggleBilled={toggleBilled} copyRapport={copyRapport} followUpRapport={followUpRapport} anchorEl={anchorEl} isOpen={!!anchorEl} onClose={() => setAnchorEl(null)} />
      <PostItDialog rapport={props.rapport} setRapports={props.setRapports} isOpen={isPostItOpen} onClose={() => setIsPostItOpen(false)} />
      <DeleteDialog title="Löschen" description="Wollen Sie den ausgewählten Rapport unwiderruflich löschen?" isOpen={isOpen} onClose={() => setIsOpen(false)} onConfirm={deleteForever} />
      <DeleteDialog title="In Papierkorb verschieben" description="Wollen Sie den ausgewählten Rapport in den Papierkorb verschieben?" isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)} onConfirm={deleteRapport} />
    </Box>
  );
};

export default RapportListEntry;