import { EldasDto } from "../dto/catalogs/eldas-dto";
import { NpkDto } from "../dto/catalogs/npk-dto";
import { SteigerDto } from "../dto/catalogs/steiger-dto";
import authorizedApi from "./api";

const MaterialAPI = {
  searchSteigerMaterial: async (id?: number, page?: number, pageSize?: number, search?: string, includePrices?: boolean): Promise<SteigerDto[]> => {
    let queryParams: any = {};
    if (id !== undefined) {
      queryParams.id = id;
    }
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }
    if (includePrices !== undefined) {
      queryParams.includePrices = includePrices;
    }

    const response = await authorizedApi.get('/material/steiger/search', { params: queryParams });
    return response.data as SteigerDto[];
  },

  searchEldasMaterial: async (page?: number, pageSize?: number, search?: string, includePrices?: boolean): Promise<EldasDto[]> => {
    let queryParams: any = {};
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }
    if (includePrices !== undefined) {
      queryParams.includePrices = includePrices;
    }

    const response = await authorizedApi.get('/material/eldas/search', { params: queryParams });
    return response.data as EldasDto[];
  },

  getNpkMaterial: async (id?: number): Promise<NpkDto[]> => {
    const queryParams = { id: id };
    const response = await authorizedApi.get('/material/npk', { params: queryParams });
    return response.data as NpkDto[];
  },

  searchNpkMaterial: async (page?: number, pageSize?: number, search?: string, includePrices?: boolean): Promise<NpkDto[]> => {
    let queryParams: any = {};
    if (page !== undefined) {
      queryParams.page = page;
    }
    if (pageSize !== undefined) {
      queryParams.pageSize = pageSize;
    }
    if (search !== undefined) {
      queryParams.search = search;
    }
    if (includePrices !== undefined) {
      queryParams.includePrices = includePrices;
    }

    const response = await authorizedApi.get('/material/npk/search', { params: queryParams });
    return response.data as NpkDto[];
  },

  loadPrices: async (): Promise<string> => {
    const response = await authorizedApi.post('/material/load-prices');
    return response.data as string;
  },

  cancelPriceLoading: async (): Promise<boolean> => {
    const response = await authorizedApi.post('/material/cancel');
    return response.data as boolean;
  },
};

export default MaterialAPI;