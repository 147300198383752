import { Box, Button, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { Rapport } from "../models/rapport";
import { useNavigate } from "react-router-dom";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaidIcon from '@mui/icons-material/Paid';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Dispatch, SetStateAction } from "react";

type RapportCardProps = {
  rapport: Rapport;
  setRapports: Dispatch<SetStateAction<Rapport[]>>;
};

const RapportCard = (props: RapportCardProps) => {
  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        height: 330,
        width: 500,
        padding: 2,
        gap: 2,
        border: 1,
        borderRadius: 1,
        borderColor: (theme) => theme.palette.grey[400],
        background: (theme) => theme.palette.grey[100],
        flexWrap: 'nowrap',
        overflow: 'hidden',
      }}
    >
      <Stack sx={{ gap: 2, flex: 1, }}>
        <Box display='flex' flexDirection='row'>
          <Typography
            sx={{
              flex: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontWeight: 'bold',
            }}
          >
            {props.rapport.betreff}
          </Typography>
          <Box sx={{ paddingLeft: (theme) => theme.spacing(2), paddingRight: (theme) => theme.spacing(1), }}>
            <Tooltip children={<LocalShippingIcon color={props.rapport.jobDone ? "primary" : "disabled"} />} title="Arbeit erledigt?" />
          </Box>
          <Tooltip children={<PaidIcon color={props.rapport.billed ? "primary" : "disabled"} />} title="Verrechnet?" />
        </Box>
        <Box display='flex' flexDirection='row' gap={2} width='100%'>
          <Paper sx={{
            padding: 2,
            width: '60%',
            height: 120,
          }}>
            <Box display='flex' flexDirection='row' gap={2}>
              <LocationOnIcon />
              <Typography sx={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
                Ausführungsort
              </Typography>
            </Box>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].firstname} {props.rapport.customers[1].lastname}</Typography>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].street}</Typography>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].postalCode} {props.rapport.customers[1].city}</Typography>
            <Typography sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}>{props.rapport.customers[1].canton} {props.rapport.customers[1].country}</Typography>
          </Paper>
          <Paper sx={{
            padding: 2,
            width: '40%',
          }}>
            <Box display='flex' flexDirection='row'>
              <AccessTimeIcon />
              <Typography sx={{
                flex: 1,
                textAlign: 'center',
                fontWeight: 'bold',
              }}>
                Termin
              </Typography>
            </Box>
            <Typography><strong>Datum:</strong> {props.rapport.dueDate?.toLocaleDateString()}</Typography>
            <Typography><strong>Uhrzeit:</strong> {props.rapport.dueDate?.toLocaleTimeString()}</Typography>
          </Paper>
        </Box>
        <Typography sx={{height: 70, width: '100%', textOverflow: 'ellipsis', overflow: 'hidden',}}>{props.rapport.description}</Typography>
      </Stack>
      <Button variant="outlined" sx={{ width: 100, bottom: 0, background: (theme) => theme.palette.background.paper }} onClick={() => navigate(`/rapport/${props.rapport.id}`)}>Details</Button>
    </Stack>
  );
};

export default RapportCard;